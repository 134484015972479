<template>
	<el-drawer
		:append-to-body="true"
		:close-on-press-escape="false"
		:wrapperClosable="false"
		title="编辑批复信息"
		:visible.sync="drawer_"
		direction="rtl"
		custom-class="demo-drawer"
		ref="drawer"
		size="70%"
		@open="open"
		@close="
			() => {
				$emit('closeVisible');
			}
		"
	>
		<div class="demo-drawer__content" style="width: 100%">
			<div class="edit" style="padding: 0 30px; width: 100%">
				<el-form
					:model="ruleForm"
					status-icon
					ref="subRuleForm"
					class="demo-ruleForm"
					label-position="rigth"
					label-width="170px"
				>
					<el-form-item label="产品名称:">
						<el-input
							disabled
							v-model="ruleForm.financialProductName"
						></el-input>
					</el-form-item>
					<el-form-item label="融资期限(月):">
						<el-input
							onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							placeholder="请输入融资期限(月)"
							v-model="ruleForm.loanDeadline"
						></el-input>
					</el-form-item>
					<el-form-item label="批复时间:">
						<el-date-picker
							type="datetime"
							placeholder="请选择生日时间"
							v-model="ruleForm.approvalTime"
							style="width: 100%"
							value-format="yyyy-MM-dd HH:mm:ss"
						></el-date-picker>
					</el-form-item>
					<el-form-item label="批复有效期(月):">
						<el-input
							onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							placeholder="请输入批复有效期"
							v-model="ruleForm.approvalValidityTerm"
						></el-input>
					</el-form-item>
					<el-form-item label="批复金额(万元):">
						<el-input
							onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							placeholder="批复金额(万元)"
							v-model="ruleForm.approvalAmount"
						></el-input>
					</el-form-item>
					<el-form-item label="每月还款日:">
						<el-input
							onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							placeholder="请输入每月还款日"
							v-model="ruleForm.repaymentDate"
						></el-input>
					</el-form-item>
					<el-form-item label="批复利率/费率(%):">
						<el-input
							onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
							placeholder="请输入批复利率/费率"
							v-model="ruleForm.approvalRate"
						></el-input>
					</el-form-item>
					<el-form-item label="还款方式:">
						<el-input
							type="textarea"
							:autosize="{ minRows: 1, maxRows: 14 }"
							placeholder="请输入还款方式	"
							v-model="ruleForm.repaymentMethod"
						></el-input>
					</el-form-item>
					<el-form-item label="贷款担保条件:">
						<el-input
							type="textarea"
							:autosize="{ minRows: 1, maxRows: 14 }"
							placeholder="请输入贷款担保条件"
							v-model="ruleForm.loanGuaranteeConditions"
						></el-input>
					</el-form-item>
					<el-form-item label="贷款前提条件:">
						<el-input
							type="textarea"
							:autosize="{ minRows: 1, maxRows: 14 }"
							placeholder="请输入贷款前提条件"
							v-model="ruleForm.loanPrerequisites"
						></el-input>
					</el-form-item>
					<el-form-item label="其他配合要求:">
						<el-input
							type="textarea"
							:autosize="{ minRows: 1, maxRows: 14 }"
							placeholder="请输入其他配合要求"
							v-model="ruleForm.otherRequirements"
						></el-input>
					</el-form-item>
				</el-form>

				<div class="demo-drawer__footer">
					<el-button @click="postFmFinanceSubject" type="primary"
						>保存</el-button
					>

					<el-button @click="$emit('closeVisible')">关 闭</el-button>
				</div>
			</div>
		</div>
	</el-drawer>
</template>

<script>
import { updateSchemeApprovalInfo } from '@/api/index.js';
export default {
	name: 'editDrawer',
	props: {
		editVisible: {
			type: Boolean,
			required: true,
		},
		params: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			ruleForm: {
				schemeApprovalId: '',
				financialProductName: '',
				approvalTime: '',
				approvalAmount: '',
				approvalRate: '',
				loanGuaranteeConditions: '',
				loanPrerequisites: '',
				otherRequirements: '',
				loanDeadline: '',
				approvalValidityTerm: '',
				repaymentDate: '',
				repaymentMethod: '',
			},
		};
	},
	methods: {
		async postFmFinanceSubject() {
			const {
				schemeApprovalId,
				financialProductName,
				approvalTime,
				approvalAmount,
				approvalRate,
				loanGuaranteeConditions,
				loanPrerequisites,
				otherRequirements,
				loanDeadline,
				approvalValidityTerm,
				repaymentDate,
				repaymentMethod,
			} = this.ruleForm;
			const res = await updateSchemeApprovalInfo({
				schemeApprovalId,
				financialProductName,
				approvalTime,
				approvalAmount,
				approvalRate,
				loanGuaranteeConditions,
				loanPrerequisites,
				otherRequirements,
				loanDeadline,
				approvalValidityTerm,
				repaymentDate,
				repaymentMethod,
			});
			if (res.returncode == 0) {
				this.$confirm('保存成功', '提示', {
					confirmButtonText: '确定',
					type: 'success',
					showCancelButton: false,
				});
				this.$emit('successVisible', this.ruleForm);
			}
		},
		open() {
			this.ruleForm = JSON.parse(JSON.stringify(this.params));
		},
	},
	created() {},

	computed: {
		drawer_: {
			get() {
				return this.editVisible;
			},
			set(v) {
				this.$emit('update:editVisible', v);
			},
		},
	},
};
</script>
<style lang="scss" scoped>
::v-deep .demo-ruleForm {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
::v-deep .demo-ruleForm > div {
	width: 48%;
}
::v-deep .demo-drawer__footer {
	display: flex;
	justify-content: center;
	width: 100%;
	padding-bottom: 20px;
	.el-button {
		width: 15%;
	}
}
::v-deep .read .el-form-item {
	margin-bottom: 0;
}
</style>
